@import 'colors';

label.toggle {
  position: relative;
  display: block;
  height: 1rem;
  width: 2.2rem;
  background: var(--toggler-disabled-background);
  border-radius: 5rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:after {
    position: absolute;
    left: -0.1rem;
    top: -0.15rem;
    display: block;
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 5rem;
    background: $white-three;
    box-shadow: 0 0.15rem 0.15rem rgba(0, 0, 0, 0.05);
    content: '';
    transition: all 0.3s ease;
  }

  &:active:after {
    transform: scale(1.15, 0.85);
  }

  &.checked {
    background: var(--toggler-enabled-background);

    &:after {
      left: 1rem;
      background: var(--toggler-slider-selected);
      box-shadow: 0 0 4px var(--shadow);
    }
  }

  &.disabled {
    background: #d5d5d5;
    pointer-events: none;

    &:after {
      background: #bcbdbc;
    }

    &.checked {
      &:after {
        left: 1rem;
        background: var(--toggler-slider-selected);
        box-shadow: 0 0 4px var(--shadow);
      }
    }
  }
}

input[type='checkbox'].toggle {
  &:checked ~ label {
    background: var(--toggler-enabled-background);

    &:after {
      left: 1rem;
      background: var(--toggler-slider-selected);
    }
  }

  &:disabled ~ label {
    background: #d5d5d5;
    pointer-events: none;

    &:after {
      background: #bcbdbc;
    }
  }

  &.hidden {
    display: none;
  }
}

.dropdown {
  &.open {
    .dropdown-menu {
      opacity: 1;
      pointer-events: all;
    }
  }

  .dropdown-menu {
    position: absolute;
    z-index: 15;
    opacity: 0;
    pointer-events: none;
  }
}
