@media screen and (min-width: 1770px) {
  html,
  body {
    font-size: 20px;
  }
}
@media screen and (max-width: 1024px) {
  html,
  body {
    font-size: 16px;
  }
}
