/**

 Goal was for theme colors to replace those. 
 Now new goal is to use imported colors only 
 aswell in theme failes and to remove the super 
 old color variables

**/

$black: rgb(0, 0, 0); //#000000
$rich-black: rgb(31, 31, 31); //#1f1f1f
$white: rgb(255, 255, 255); //#ffffff
$white-two: rgb(249, 249, 249); //#f9f9f9
$white-three: rgb(216, 216, 216); //#d8d8d8
$pale-gray: rgb(242, 243, 245); //#f2f3f5
$pale-gray-t50: rgba(240, 241, 243, 0.5); //#f2f3f580
$pale-gray-dark: rgb(235, 237, 240); //#ebedf0
$pale-gray-dark-t50: rgba(235, 237, 240, 0.5); //#ebedf080
$light-gray: rgb(238, 238, 236); //#efefed
$cool-gray: rgb(147, 149, 151); //#939597
$cool-gray-light: rgb(179, 180, 182); //#b3b4b6
$silver: rgb(225, 227, 230); //#e1e3e6
$silver-two: rgb(232, 233, 235); //#e8e9eb
$dusk: rgb(66, 68, 98); //#424462
$dusk-t50: rgba(66, 68, 98, 0.5); //#42446280
$dusk-two: rgb(60, 57, 111); //#3c396f
$dark: rgb(42, 42, 60); //#2a2a3c
$lipstick: rgb(191, 36, 104); //#bf2468
$cerise: rgb(237, 30, 121); //#ed1e79
$orange-yellow: rgb(255, 172, 0); //#ffac00
$marigold: rgb(255, 197, 0); //#ffc500
$yellow-green: rgb(160, 212, 44); //#a0d42c
$aqua-green: rgb(22, 226, 168); //#16e2a8
$cornflower: rgb(98, 105, 255); //#6269ff
$cornflower-light: rgba(98, 106, 255, 0.5); //#626aff80
$cornflower-dim: rgb(221, 223, 255); //#dddfff
$cornflower-dim-light: rgba(221, 223, 255, 0.5); //#dddfff80
$lavender-blue: rgb(146, 129, 244); //#9281f4
$soft-blue: rgb(108, 156, 225); //#6c9ce1
$shadow: rgba(135, 135, 135, 0.5); //#86868680
$alert: rgb(254, 244, 248); //#fef4f8
$warning: rgb(250, 240, 230); //#fef4f8
$white-four: rgb(200, 200, 200); //#c8c8c8
$aqua-green-light: rgb(190, 232, 210); //#bee8d2
$aqua-green-mid: rgb(127, 229, 171); //#7fe5ab
$marigold-light: rgb(251, 234, 174); //#fbeaae
$marigold-mid: rgb(255, 215, 80); //#ffd750
$cerise-light: rgb(255, 196, 196); //#ffc4c4
$cerise-mid: rgb(225, 149, 149); //#e19595
$red: rgb(255, 0, 0); //#FF0000

/**

 Import of new color scheme

**/

@import 'colors/still';
@import 'colors/hot';
@import 'colors/cold';
@import 'colors/good';
@import 'colors/sore';
@import 'colors/warm';
@import 'colors/freeze';
@import 'colors/goodX';
@import 'colors/soreX';
@import 'colors/freezeX';
@import 'colors/warmX';
