@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700');
@import url('https://fonts.googleapis.com/css?family=Anonymous+Pro');

@import 'themes/dark.theme';
@import 'themes/light.theme';

html,
body {
  background-color: var(--background-default);
  color: var(--text-color);
  font-family: var(--font-face), sans-serif;
  font-weight: 300;
  font-size: var(--font-size);
  line-height: normal;
}

app {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.cdk-overlay-container {
  z-index: 71000; // dialog have z-index: 70000 + index
}
