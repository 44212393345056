@import 'colors';

//Health Status text treatments
.t-excellent {
  color: var(--is-excellent);
}
.t-good {
  color: var(--is-good);
}
.t-fair {
  color: var(--is-fair);
}
.t-poor {
  color: var(--is-poor);
}
