button {
  all: unset;
  text-align: center;
  transition: 0.2s ease-in-out;

  svg {
    all: unset;
  }

  &.textCenter {
    justify-content: center;
  }

  &.action {
    font-size: 1rem;
    line-height: 1rem;
    color: var(--b2bButtonFontColor);
    background: var(--b2bButtonActionBg);
    border: 1px solid var(--b2bButtonActionBorder);
    border-radius: 1.5rem;
    padding: calc(0.75rem - 1px) 1.5rem;
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: var(--b2bButtonActionHover);
      border: 1px solid var(--b2bButtonActionHoverBorder);
    }

    &.disabled,
    &:disabled {
      opacity: 0.3;
      pointer-events: none;
      cursor: default;

      &.allow-pointer-events {
        pointer-events: all;
      }
    }

    &.small {
      font-size: 0.8rem;
      padding: 0.2rem 1rem;

      icon svg {
        width: 0.8rem;
        height: 0.8rem;
      }
    }

    &.medium {
      font-size: 0.8rem;
      padding: 0.5rem 1rem;
    }

    icon {
      margin: 0 0.5rem;

      svg {
        width: 1rem;
        height: 1rem;
      }
    }

    icon:first-child {
      margin-left: -0.25rem;
    }

    icon:last-child {
      margin-right: -0.25rem;
    }
  }

  &.primary {
    border-radius: 1.5rem;
    border: 1px solid var(--b2bButtonPrimaryBorder);
    padding: calc(0.75rem - 1px) calc(2rem - 1px);
    line-height: 1rem;
    font-size: 1rem;
    color: var(--b2bButtonFontColor);
    cursor: pointer;

    &:hover {
      border-color: var(--b2bButtonPrimaryBorderHover);
    }

    &:active {
      border-color: var(--b2bButtonPrimaryBorderSelected);
    }

    &.disabled,
    &:disabled {
      opacity: 0.3;
      border-color: var(--b2bButtonPrimaryBorderHover);
      pointer-events: none;
      cursor: default;

      &.allow-pointer-events {
        pointer-events: all;
      }
    }
  }

  &.secondary {
    border-radius: 1.5rem;
    border: 1px solid var(--b2bButtonSecondaryBorder);
    padding: calc(0.75rem - 1px) calc(2rem - 1px);
    line-height: 1rem;
    font-size: 1rem;
    color: var(--b2bButtonFontColor);
    cursor: pointer;

    &:hover {
      border-color: var(--b2bButtonSecondaryBorderHover);
    }

    &.disabled,
    &:disabled {
      opacity: 0.3;
      border-color: var(--b2bButtonSecondaryBorder);
      pointer-events: none;
      cursor: default;

      &.allow-pointer-events {
        pointer-events: all;
      }
    }
  }

  &.tertiary {
    color: var(--b2bButtonTertiaryFontColor);
    padding: 0.75rem 2rem;
    line-height: 1rem;
    font-size: 1rem;
    cursor: pointer;

    &:hover {
      color: var(--b2bButtonTertiaryFontColorHover);
    }

    &.light {
      color: var(--b2bButtonTertiaryLightFontColor);

      &:hover {
        color: var(--b2bButtonTertiaryFontColorHover);
      }
    }

    &.disabled,
    &:disabled {
      opacity: 0.3;
      color: var(--b2bButtonTertiaryFontColorHover);
      pointer-events: none;
      cursor: default;

      &.allow-pointer-events {
        pointer-events: all;
      }
    }

    &.small {
      font-size: 0.8rem;
      padding: 0.2rem 1rem;

      icon svg {
        width: 0.8rem;
        height: 0.8rem;
      }
    }

    &.medium {
      font-size: 0.8rem;
      padding: 0.5rem 1rem;

      icon svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }

  &.quaternary {
    color: var(--b2bButtonQuaternaryFontColor);
    padding: 0.25rem 0.6rem;
    line-height: 0.75rem;
    font-size: 0.75rem;
    cursor: pointer;

    &:hover {
      color: var(--b2bButtonQuaternaryFontColorHover);
    }

    &.disabled,
    &:disabled {
      opacity: 0.3;
      color: var(--b2bButtonQuaternaryFontColorHover);
      pointer-events: none;
      cursor: default;

      &.allow-pointer-events {
        pointer-events: all;
      }
    }
  }

  &.icon {
    padding: 0.75rem;
    border-radius: 50%;
    line-height: 0;
    cursor: pointer;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }

    &.small {
      padding: 0.5rem;

      &:hover {
        padding: calc(0.5rem - 1px);
      }

      svg {
        width: 1rem;
        height: 1rem;
      }
    }

    &.selected {
      background: var(--b2bButtonIconSelected);
    }

    &:hover {
      background: var(--b2bButtonIconHover);
      padding: calc(0.75rem - 1px);
      border: 1px solid var(--b2bButtonIconHoverBorder);
    }

    &.disabled,
    &:disabled {
      opacity: 0.3;
      pointer-events: none;
      cursor: default;

      &.allow-pointer-events {
        pointer-events: all;
      }
    }
  }

  &.super-primary {
    background: var(--b2bButtonSuperPrimaryBg);
    border-radius: 1.5rem;
    border: 1px solid transparent;
    padding: calc(0.75rem - 1px) calc(2rem - 1px);
    line-height: 1rem;
    font-size: 1rem;
    color: var(--b2bButtonSuperPrimaryColor);
    cursor: pointer;

    &:hover {
      background: var(--b2bButtonSuperPrimaryHoverBg);
      border-color: var(--b2bButtonSuperPrimaryHoverBorder);
    }

    &.selected,
    &:active {
      background: var(--b2bButtonSuperPrimarySelectedBg);
      border-color: transparent;
    }

    &.disabled,
    &:disabled {
      opacity: 0.3;
      pointer-events: none;
      cursor: default;

      &.allow-pointer-events {
        pointer-events: all;
      }
    }

    &.small {
      font-size: 0.8rem;
      padding: 0.2rem 1rem;

      icon svg {
        width: 0.8rem;
        height: 0.8rem;
      }
    }

    &.medium {
      font-size: 0.8rem;
      padding: 0.5rem 1rem;

      icon svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }
  &.chat-select {
    border-radius: 1rem;
    border: 1px solid var(--range-slider-bg);
    padding: 0.75rem;
    line-height: 1rem;
    background-color: var(--b2bButtonIconHover);
    font-size: 1rem;
    color: var(--b2bButtonFontColor);
    cursor: pointer;
    font-weight: 400;
    font-size: 0.875rem;

    &:hover {
      border: 1px solid var(--dropDownCheckSelected);
    }

    &.selected {
      color: var(--b2bChatButtonSelectedFont);
      background-color: var(--b2bChatButtonSelected);
    }
  }
}
