@import 'colors';

$type-colors: (
  string: var(--chart-purple),
  number: #009688,
  boolean: #b938a4,
  date: #05668D,

  array: #999,
  object: #999,
  function: #999,

  'null': #fff,
  undefined: #fff
);

.ngx-json-viewer .segment-key {
  color: var(--confirm-text) !important;
}

@each $type, $color in $type-colors {
  .ngx-json-viewer .segment-type-#{$type} > .segment-main > .segment-value {
    color: $color !important;
  }
}
