@import 'colors';

input[type='text'],
input[type='email'],
input[type='password'],
input[type='number'] {
  padding: 1.2rem;
  padding-top: 1rem;
  margin-top: 0.8rem;
  outline: none;
  border-radius: 0.2rem;
  box-sizing: border-box;
  font-weight: 300;
  border: 2px solid transparent;
  transition: all 0.3s ease-in-out;
  line-height: normal;
  background-color: var(--input-background);
  color: var(--input-text);

  &.oval {
    width: 100%;
    background: $white-two;
    border-radius: 5rem;
    padding: 0.8rem 1.6rem 1rem 1.6rem;
    margin: 0;
  }

  &::placeholder {
    color: var(--input-placeholder);
  }

  &[disabled] {
    opacity: 0.5;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
