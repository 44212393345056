[theme='light'] {
  --font-face: Open sans; // Wigrum;
  --font-size: 18px;
  --text-color: rgb(66, 68, 98);
  --text-title: rgb(255, 255, 255);
  --text-contrast: rgb(0, 0, 0);
  --text-light: rgb(255, 255, 255);
  --text-dark: rgb(255, 255, 255);
  --text-sub: rgb(147, 149, 151);
  --text-sidemenu: rgb(255, 255, 255);
  --sidemenu-hover: rgba(240, 241, 243, 0.5);
  --sidemenu-active-outline: 1px solid var(--text-contrast);
  --background-default: rgb(232, 233, 235);
  --header-background: rgb(255, 255, 255);
  --settings-icon: rgb(160, 161, 176);
  --settings-icon-hover: rgb(66, 68, 98);
  --settings-stat: rgb(88, 91, 200);
  --button-background: rgb(66, 68, 98);
  --button-text: rgb(255, 255, 255);
  --button-light-background: rgb(255, 255, 255);
  --button-light-text: rgb(66, 68, 98);
  --button-pill: rgb(147, 149, 151);
  --button-pill-text: rgb(98, 105, 255);
  --input-background: transparent;
  --input-background-fill: rgb(255, 255, 255);
  --input-background-fill-dark: rgb(249, 249, 249);
  --input-text: rgb(66, 68, 98);
  --input-placeholder: rgb(147, 149, 151);
  --input-border: rgb(98, 105, 255);
  --input-border-default: rgb(98, 105, 255);
  --input-border-radius: 0;
  --link-text: rgb(93, 93, 93);
  --link-color: rgb(98, 105, 255);
  --warning-text: rgb(191, 36, 104);
  --warning-connection: rgb(250, 20, 120, 1);
  --warning-border: rgb(191, 36, 104);
  --warning-background: rgb(255, 255, 255);
  --search-background: rgb(249, 249, 249);
  --toggler-disabled-background: rgb(242, 242, 242);
  --toggler-disabled-border: 1px solid rgb(196, 196, 196);
  --toggler-enabled-background: rgba(98, 106, 255, 0.5);
  --toggler-background: rgb(255, 255, 255);
  --toggler-background-light: rgb(232, 233, 235);
  --toggler-slider-background: rgb(255, 255, 255);
  --toggler-slider-border: 1px solid rgb(98, 105, 255);
  --toggler-switch-border: 1px solid rgb(222, 224, 226);
  --toggler-slider-disabled: rgb(66, 68, 98);
  --toggler-slider-selected: rgb(101, 108, 255);
  --toggler-text: rgb(17, 17, 17);
  --toggler-slider-border-disabled: none;
  --tab-selected: rgb(98, 105, 255);
  --tab-selected-pill: rgb(255, 255, 255);
  --tab-selected-pill-text: rgb(17, 17, 17);
  --tab-hover: rgba(221, 223, 255, 0.5);
  --tab-border: rgb(221, 223, 255);
  --tab-text: rgb(66, 68, 98);
  --dropdown-background: rgb(66, 68, 98);
  --dropdown-select: rgb(196, 196, 196);
  --results-dropdown-background: rgb(255, 255, 255);
  --results-select-background: rgb(225, 227, 230);
  --results-select-text: rgb(66, 68, 98);
  --pill-background: rgb(255, 255, 255);
  --pill-light: rgb(235, 237, 240);
  --pill-hover: rgb(225, 227, 230);
  --pill-text: rgb(66, 68, 98);
  --pill-badge: rgb(66, 68, 98);
  --pill-disabled: rgb(249, 249, 249);
  --pill-border: rgb(225, 227, 230);
  --pill-incomplete: rgb(237, 30, 121);
  --pill-radio-selected: rgb(225, 227, 230);
  --pill-selected: rgb(66, 68, 98);
  --pill-selected-text: rgb(196, 196, 196);
  --pill-radio-hover: rgb(249, 249, 249);
  --pill-radio-circle: rgb(255, 255, 255);
  --pill-label: rgb(255, 255, 255);
  --bubble-background: rgb(255, 255, 255);
  --bubble-alert: rgb(254, 244, 248);
  --bubble-warning: rgb(250, 240, 230);
  --modal-background: rgb(255, 255, 255);
  --modal-border: rgb(77, 77, 77);
  --modal-overlay: rgba(255, 255, 255, 0.5);
  --toolbar-outline: rgb(162, 162, 162);
  --modal-shaded: rgb(225, 227, 230);
  --sidebar-background: rgb(255, 255, 255);
  --sidemenu-background: rgb(255, 255, 255);
  --sidemenu-icon-fill: rgb(17, 17, 17);
  --sidemenu-selected: rgb(242, 242, 242);
  --sidemenu-border: 1px solid #dee0e2;
  --sidemenu-color: rgb(77, 77, 77);
  --light-background: rgb(255, 255, 255);
  --light-border: rgb(162, 162, 162);
  --box-header: rgb(249, 249, 249);
  --box-content: rgb(255, 255, 255);
  --box-header-dark: rgb(249, 249, 249);
  --box-content-dark: rgb(255, 255, 255);
  --box-highlight: rgb(241, 242, 249);
  --node-device-background: rgb(232, 233, 235);
  --events-background: rgb(232, 233, 235);
  --login-button-background: rgb(66, 68, 98);
  --icon-circle: rgb(255, 255, 255);
  --icon-stroke: rgb(66, 68, 98);
  --icon-fill: rgb(66, 68, 98);
  --icon-disabled: rgba(68, 68, 68, 0.25);
  --icon-background: rgb(255, 255, 255);
  --icon-confirm: rgb(22, 226, 168);
  --icon-cancel: rgb(237, 30, 121);
  --strip-background: rgb(255, 255, 255);
  --tooltip-background: rgb(66, 68, 98);
  --tooltip-text: rgb(255, 255, 255);
  --tooltip-border: rgb(225, 227, 230);
  --tooltip-light-background: rgb(255, 255, 255);
  --tooltip-light-text: rgb(66, 68, 98);
  --tooltip-light-border: rgb(225, 227, 230);
  --tooltip-modal: rgba(135, 135, 135, 0.5);
  --toast-background: rgb(66, 68, 98);
  --toast-border: rgb(255, 255, 255);
  --toast-content: rgb(255, 255, 255);
  --table-stripped-light: rgb(249, 249, 249);
  --table-stripped-dark: rgb(225, 227, 230);
  --table-stripped-error-background: rgb(255, 196, 196);
  --table-stripped-error-border: rgb(225, 149, 149);
  --table-stripped-warn-background: rgb(254, 244, 248);
  --table-stripped-warn-border: rgb(255, 196, 196);
  --table-stripped-selected: rgb(66, 68, 98);
  --table-stripped-selected-text: rgb(196, 196, 196);
  --background-overlay: rgb(255, 255, 255);
  --chart-hover: linear-gradient(rgb(255, 255, 255), rgb(249, 249, 249));
  --chart-bar: rgb(146, 129, 244);
  --chart-gradient-light: rgb(255, 255, 255);
  --chart-gradient-dark: rgb(249, 249, 249);
  --chart-grid: rgb(238, 238, 236);
  --chart-border: rgb(225, 227, 230);
  --flash-0: rgb(0, 0, 0);
  --flash-50: rgb(238, 238, 236);
  --flash-100: rgb(238, 238, 236);
  --point-placeholder: rgb(238, 238, 236);
  --confirm-background: rgb(22, 226, 168);
  --confirm-text: rgb(22, 226, 168);
  --cancel-background: rgb(237, 30, 121);
  --cancel-text: rgb(237, 30, 121);
  --validation: rgb(237, 30, 121);
  --link: rgb(66, 68, 98);
  --link-mlo: rgb(162, 162, 162);
  --link-6g: rgb(15, 220, 255);
  --link-5g: rgb(22, 226, 168);
  --link-2g: rgb(98, 105, 255);
  --chart-yellow: rgb(255, 197, 0);
  --chart-green: rgb(160, 212, 44);
  --chart-purple: rgb(146, 129, 244);
  --is-offline: rgb(147, 149, 151);
  --is-disconnected: rgb(237, 30, 121);
  --is-connected: rgb(22, 226, 168);
  --is-enabled: rgb(22, 226, 168);
  --is-calculating: rgb(200, 200, 200);
  --is-busy: rgb(98, 105, 255);
  --is-excellent: rgb(23, 227, 174);
  --is-good: rgb(22, 226, 168);
  --is-fair: rgb(160, 212, 44);
  --is-warn: rgb(255, 197, 0);
  --is-poor: rgb(237, 30, 121);
  --is-error: rgb(237, 30, 121);
  --is-incomplete: rgb(237, 30, 121);
  --is-contentaccess: rgb(22, 226, 168);
  --is-iotprotect: rgb(66, 68, 98);
  --is-adblocking: rgb(108, 156, 225);
  --is-secureprotect: rgb(225, 149, 149);
  --is-timelineseries1: rgb(70, 240, 225);
  --is-timelineseries2: rgb(108, 156, 225);
  --is-timelineseries3: rgb(225, 149, 149);
  --is-timelineseries4: rgb(66, 68, 98);
  --is-timelineseries5: rgb(170, 76, 247);
  --is-timelineseries6: rgb(22, 226, 168);
  --is-timelineseries7: rgb(226, 144, 22);
  --is-timelineseries8: rgb(214, 50, 58);
  --is-good-mid: rgb(127, 229, 171);
  --is-warn-mid: rgb(255, 215, 80);
  --is-poor-mid: rgb(225, 149, 149);
  --is-good-light: rgb(190, 232, 210);
  --is-warn-light: rgb(251, 234, 174);
  --is-poor-light: rgb(255, 196, 196);
  --is-good-dim: rgba(22, 226, 168, 0.1);
  --is-warn-dim: rgba(255, 197, 0, 0.1);
  --is-poor-dim: rgba(237, 30, 121, 0.1);
  --is-download: rgb(146, 129, 244);
  --is-upload: rgb(108, 156, 225);
  --divider: rgb(66, 68, 98);
  --divider-50: rgba(66, 68, 98, 0.5);
  --divider-light: rgb(230, 230, 230);
  --dropdown-shadow: 0 0 1rem rgba(66, 68, 98, 0.5);
  --shadow: rgba(135, 135, 135, 0.5);
  --diagram-shadow: rgba(0, 0, 0, 0.5);
  --overlay: rgba(170, 170, 170, 0.5);
  --overlay-opacity: 0.5;
  --transparent: rgba(0, 0, 0, 0);
  --white: rgb(255, 255, 255);
  --logo: rgb(17, 17, 17);
  --logo-fill: rgb(70, 70, 70);
  --invert: invert(0);

  --dropDownBg: #f0f3f5;
  --dropDownHoverBg: #dee0e2;
  --dropDownActiveBg: #f7f8fa;
  --dropDownDisabled: #dee0e2;
  --dropDownEnabled: #4d4d4d;
  --dropDownBorderDisabled: #dee0e2;
  --dropDownBorderNormal: #c6c6c6;
  --dropdownBorderActive: var(--dropDownBorderNormal);
  --dropdownBorderHover: #c6c6c6;
  --dropDownListBg: var(--background-default);
  --dropDownListHover: rgba(255, 255, 255, 0.8);
  --dropDownListBorder: #c6c6c6;
  --dropDownCheckSelected: #4d4d4d;
  --dropDownCheckHover: #c6c6c6;
  --dropDownCheckHoverSelected: #0ebb8d;
  --dropDownNoMatchColor: #8f9397;
  --dropDownIconHighlightColor: rgba(0, 0, 0, 0.13);

  --mainMenuBg: #ffffff;
  --mainMenuTitleColor: #8f9397;
  --mainMenuLvl3SelectColor: #6269ff;
  --mainMenuLvl3HoverColor: #f7f8fa;
  --mainMenuHoverColor: rgba(241, 241, 241, 0.8);
  --mainMenuSelectedBgColor: #f0f3f5;
  --mainMenuLvl2BgColor: transparent;
  --mainMenuLvl2OpenedOrChildSelectedColor: transparent;

  --labeledInputTextColor: #000000;
  --labeledInputBorderColor: #ccceff;
  --labeledInputErrorColor: #fa1478;
  --labeledInputSuccessColor: #17e3ae;
  --labeledInputHighlightColor: #6269ff;

  --graphAxis: #f0f3f5;
  --graphAxisText: #8f9397;
  --graphBarVerticalLineColor: var(--graphAxisText);
  // --graphLineToolTipTextColor: #ffffff;
  // --graphLineToolTipBgColor: #8f9397;
  // --graphBarToolTipBgColor: #ffffff;
  // --graphStackAxisText: #dee0e2;
  // --legendTextColor: #dee0e2;
  --graphNetworkStatusOkLine: #17e3ae;
  --graphNetworkStatusOkRect: var(--graphNetworkStatusOkLine);
  --graphNetworkStatusNullRect: var(--graphAxis);
  --graphNetworkStatusError: #fa1478;
  --graphNetworkStatusWarningStart: #f8e4a2;
  --graphNetworkStatusWarningEnd: #ffc500;
  --graphNetworkStatusToolTipColor: #ffffff;
  --graphNetworkStatusToolTipBgColor: #111111;
  --graphNetworkStatusToolTipShadowColor: rgba(255, 255, 255, 0.25);
  --graphNetworkStatusAxisText: var(--graphAxisText);

  --lteProgressBg: rgba(241, 241, 241, 0.8);
  --lteProgressFg: #81bb85;
  --lteTitle: #4d4d4d;
  --lteTitleDescription: #c6c6c6;
  --lteDetail: #8f9397;

  --b2bButtonFontColor: #4d4d4d;
  --b2bButtonActionBg: #dee0e2;
  --b2bButtonActionBorder: #dee0e2;
  --b2bButtonIconHover: #f7f8fa;
  --b2bButtonIconSelected: transparent;
  --b2bChatButtonSelectedFont: #ffffff;
  --b2bChatButtonSelected: rgba(51, 51, 51, 1);
  --b2bButtonIconHoverBorder: #8f9397;
  --b2bButtonActionHover: #ffffff;
  --b2bButtonActionHoverBorder: #8f9397;
  --b2bButtonPrimaryBorder: #018763;
  --b2bButtonPrimaryBorderHover: #8f9397;
  --b2bButtonSecondaryBorder: #8f9397;
  --b2bButtonSecondaryBorderHover: #dee0e2;
  --b2bButtonTertiaryFontColor: #222222;
  --b2bButtonTertiaryLightFontColor: #111111;
  --b2bButtonTertiaryFontColorHover: #8f9397;
  --b2bButtonQuaternaryFontColor: #111111;
  --b2bButtonQuaternaryFontColorHover: #8f9397;
  --b2bButtonSuperPrimaryColor: #ffffff;
  --b2bButtonSuperPrimaryBg: #5d64f2;
  --b2bButtonSuperPrimarySelectedBg: #3b41c4;
  --b2bButtonSuperPrimaryHoverBg: #5d64f2;
  --b2bButtonSuperPrimaryHoverBorder: #ffffff;

  --tooltipContractBg: #ffffff;
  --tooltipContractColor: #111111;
  --graphGood: var(--is-good);
  --graphWarning: var(--is-warn);
  --graphError: var(--is-error);

  --range-slider-bg: #c6c6c6;
  --range-slider-dot: #5c5c5c;
  --slider-background: #ffffff;
  --slider-value: #4f4f4f;
  --still50: #f7f8fa;
}
