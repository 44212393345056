.toast-container {
  font-size: 0.7rem;

  .toast {
    background-color: var(--toast-background);
    border-width: 0.1rem;
    border-style: solid;
    border-color: var(--toast-border);
    border-radius: 0.5rem;
  }
}
