[theme='dark'] {
  --font-face: Open sans; // Wigrum;
  --font-size: 18px;
  --text-color: rgba(240, 241, 243, 0.75);
  --text-title: rgba(240, 241, 243, 0.95);
  --text-contrast: rgb(255, 255, 255);
  --text-light: rgba(240, 241, 243, 0.75);
  --text-dark: rgb(12, 12, 12);
  --text-sub: rgb(147, 149, 151);
  --text-sidemenu: rgb(196, 196, 196);
  --sidemenu-hover: rgb(96, 96, 96);
  --sidemenu-active-background: var(--sidemenu-hover);
  --background-default: rgb(17, 17, 17);
  --header-background: rgb(63, 63, 63);
  --settings-icon: rgb(121, 121, 121);
  --settings-icon-hover: rgb(255, 255, 255);
  --settings-stat: rgb(101, 108, 255);
  --button-background: rgb(128, 128, 128);
  --button-text: rgb(242, 243, 245);
  --button-light-background: rgb(128, 128, 128);
  --button-light-text: rgb(242, 243, 245);
  --button-pill: rgb(147, 149, 151);
  --button-pill-text: rgb(242, 243, 245);
  --input-background: transparent;
  --input-background-fill: rgba(196, 196, 196, 0.05);
  --input-background-fill-dark: rgb(12, 12, 12);
  --input-text: rgb(196, 196, 196);
  --input-placeholder: rgba(196, 196, 196, 0.5);
  --input-border: rgb(255, 255, 255);
  --input-border-default: transparent;
  --input-border-radius: 0.2rem;
  --link-text: rgb(93, 93, 93);
  --link-color: rgb(98, 105, 255);
  --warning-text: rgb(196, 196, 196);
  --warning-connection: rgb(250, 20, 120, 1);
  --warning-border: rgb(128, 32, 32);
  --warning-background: rgb(63, 7, 7);
  --search-background: rgba(0, 0, 0, 0.25);
  --toggler-disabled-background: rgba(63, 63, 63, 0.5);
  --toggler-disabled-border: 1px solid rgb(117, 117, 117);
  --toggler-enabled-background: rgb(77, 79, 80);
  --toggler-background: rgba(255, 255, 255, 0.25);
  --toggler-background-light: rgb(88, 88, 88);
  --toggler-slider-background: rgba(255, 255, 255, 0.5);
  --toggler-slider-border: 1px solid transparent;
  --toggler-switch-border: 1px solid transparent;
  --toggler-slider-disabled: rgba(255, 255, 255);
  --toggler-slider-selected: rgb(101, 108, 255);
  --toggler-text: rgb(255, 255, 255);
  --toggler-slider-border-disabled: none;
  --tab-selected-pill: rgba(255, 255, 255, 0.05);
  --tab-selected-pill-text: rgba(255, 255, 255, 0.85);
  --tab-selected: rgb(255, 255, 255);
  --tab-hover: rgb(128, 128, 128);
  --tab-border: rgb(128, 128, 128);
  --tab-text: rgb(128, 128, 128);
  --dropdown-background: rgb(99, 99, 99);
  --dropdown-select: rgb(68, 68, 68);
  --results-dropdown-background: rgb(48, 48, 48);
  --results-select-background: rgba(255, 255, 255, 0.25);
  --results-select-text: rgb(196, 196, 196);
  --pill-background: rgb(96, 96, 96);
  --pill-light: rgb(96, 96, 96);
  --pill-hover: rgb(128, 128, 128);
  --pill-text: rgb(196, 196, 196);
  --pill-badge: rgba(255, 255, 255, 0.5);
  --pill-disabled: rgba(255, 255, 255, 0.25);
  --pill-border: rgba(255, 255, 255, 0.25);
  --pill-incomplete: rgba(255, 255, 0, 0.25);
  --pill-radio-selected: rgb(64, 64, 64);
  --pill-selected: rgb(64, 64, 64);
  --pill-selected-text: rgb(196, 196, 196);
  --pill-radio-hover: rgba(255, 255, 255, 0.25);
  --pill-radio-circle: rgb(17, 17, 17);
  --pill-label: rgb(45, 45, 45);
  --bubble-background: rgba(255, 255, 255, 0.0625);
  --bubble-alert: rgba(255, 0, 0, 0.15);
  --bubble-warning: rgba(255, 255, 0, 0.08);
  --modal-background: rgb(17, 17, 17);
  --modal-border: rgb(77, 77, 77);
  --modal-overlay: rgba(0, 0, 0, 0.5);
  --toolbar-outline: rgba(0, 0, 0, 0.5);
  --modal-shaded: rgb(64, 64, 64);
  --sidebar-background: rgb(32, 32, 32);
  --sidemenu-background: rgb(32, 32, 32);
  --sidemenu-icon-fill: rgb(196, 196, 196);
  --sidemenu-selected: rgb(64, 64, 64);
  --sidemenu-border: none;
  --sidemenu-color: inherit;
  --light-background: rgb(32, 32, 32);
  --light-border: rgb(128, 128, 128);
  --box-header: rgb(35, 35, 35);
  --box-content: rgb(26, 26, 26);
  --box-header-dark: rgb(17, 17, 17);
  --box-content-dark: rgb(17, 17, 17);
  --box-highlight: rgb(128, 128, 128);
  --node-device-background: rgb(17, 17, 17);
  --events-background: rgb(80, 80, 80);
  --login-button-background: rgb(98, 105, 255);
  --icon-circle: rgb(46, 46, 46);
  --icon-stroke: rgb(255, 255, 255);
  --icon-fill: rgb(196, 196, 196);
  --icon-disabled: rgb(196, 196, 196, 0.25);
  --icon-background: rgb(96, 96, 96);
  --icon-confirm: rgb(30, 200, 0);
  --icon-cancel: rgb(200, 0, 0);
  --strip-background: rgb(64, 64, 64);
  --tooltip-background: rgb(64, 64, 64);
  --tooltip-text: rgb(196, 196, 196);
  --tooltip-border: rgb(128, 128, 128);
  --tooltip-light-background: rgb(96, 96, 96);
  --tooltip-light-text: rgb(255, 255, 255);
  --tooltip-light-border: rgb(48, 48, 48);
  --tooltip-modal: rgba(255, 255, 255, 0.5);
  --toast-background: rgb(48, 48, 48);
  --toast-border: rgb(147, 149, 151);
  --toast-content: rgb(255, 255, 255);
  --table-stripped-light: rgb(40, 40, 40);
  --table-stripped-dark: rgb(64, 64, 64);
  --table-stripped-error-background: rgb(152, 51, 51);
  --table-stripped-error-border: rgb(255, 143, 143);
  --table-stripped-warn-background: rgb(45, 20, 20);
  --table-stripped-warn-border: rgb(101, 0, 0);
  --table-stripped-selected: rgb(196, 196, 196);
  --table-stripped-selected-text: rgb(64, 64, 64);
  --background-overlay: rgb(128, 128, 128);
  --chart-hover: linear-gradient(rgb(48, 48, 48), rgb(96, 96, 96));
  --chart-bar: rgb(0, 64, 128);
  --chart-gradient-light: rgb(48, 48, 48);
  --chart-gradient-dark: rgb(96, 96, 96);
  --chart-grid: rgb(70, 70, 70);
  --chart-border: rgb(128, 128, 128);
  --flash-0: rgb(16, 16, 16);
  --flash-50: rgb(64, 64, 64);
  --flash-100: rgb(96, 96, 96);
  --point-placeholder: rgba(255, 255, 255, 0.25);
  --confirm-background: rgb(0, 64, 0);
  --confirm-text: rgb(0, 196, 0);
  --cancel-background: rgb(0, 64, 0);
  --cancel-text: rgb(0, 196, 0);
  --validation: rgb(128, 0, 0);
  --link: rgb(128, 128, 128);
  --link-mlo: rgb(162, 162, 162);
  --link-6g: rgb(98, 105, 255);
  --link-5g: rgb(0, 128, 0);
  --link-2g: rgb(128, 128, 0);
  --chart-yellow: rgb(255, 197, 0);
  --chart-green: rgb(160, 212, 44);
  --chart-purple: rgb(146, 129, 244);
  --is-offline: rgba(255, 255, 255, 0.25);
  --is-disconnected: rgb(128, 0, 0);
  --is-connected: rgb(0, 196, 64);
  --is-enabled: rgb(0, 170, 0);
  --is-calculating: rgb(64, 64, 64);
  --is-busy: rgb(128, 128, 0);
  --is-excellent: rgb(23, 227, 174);
  --is-good: rgb(1, 182, 146);
  --is-fair: rgb(0, 170, 0);
  --is-warn: rgb(207, 168, 0);
  --is-poor: rgb(237, 30, 121);
  --is-error: rgb(64, 0, 0);
  --is-incomplete: rgb(128, 128, 0);
  --is-contentaccess: rgb(0, 170, 0);
  --is-iotprotect: rgb(76, 76, 224);
  --is-adblocking: rgb(170, 76, 247);
  --is-secureprotect: rgb(128, 128, 0);
  --is-timelineseries1: rgb(70, 240, 225);
  --is-timelineseries2: rgb(98, 105, 255);
  --is-timelineseries3: rgb(225, 149, 149);
  --is-timelineseries4: rgb(128, 128, 128);
  --is-timelineseries5: rgb(170, 76, 247);
  --is-timelineseries6: rgb(0, 128, 0);
  --is-timelineseries7: rgb(212, 120, 0);
  --is-timelineseries8: rgb(214, 50, 58);
  --is-good-mid: rgb(0, 128, 0);
  --is-warn-mid: rgb(128, 128, 0);
  --is-poor-mid: rgb(225, 149, 149);
  --is-good-light: rgb(127, 229, 171);
  --is-warn-light: rgb(189, 159, 35);
  --is-poor-light: rgb(212, 126, 126);
  --is-good-dim: rgba(0, 170, 0, 0.1);
  --is-warn-dim: rgba(207, 168, 0, 0.1);
  --is-poor-dim: rgba(184, 4, 4, 0.1);
  --is-download: rgb(0, 128, 64);
  --is-upload: rgb(98, 105, 255);
  --divider: rgb(128, 128, 128);
  --divider-50: rgba(128, 128, 128, 0.5);
  --divider-light: rgb(128, 128, 128);
  --dropdown-shadow: 0.5rem 0.5rem 0.5rem 0px rgba(0, 0, 0, 0.5);
  --shadow: rgba(0, 0, 0, 0.5);
  --diagram-shadow: rgba(255, 255, 255, 0.8);
  --overlay: rgba(70, 70, 70, 0.5);
  --overlay-opacity: 0.8;
  --transparent: rgba(0, 0, 0, 0);
  --white: rgb(255, 255, 255);
  --logo: rgb(255, 255, 255);
  --logo-fill: rgb(196, 196, 196);
  --invert: invert(1);

  --dropDownBg: #191919;
  --dropDownHoverBg: #4c4c4c;
  --dropDownActiveBg: #333333;
  --dropDownDisabled: #4d4d4d;
  --dropDownEnabled: #ffffff;
  --dropDownBorderDisabled: var(--dropDownBg);
  --dropDownBorderNormal: var(--dropDownBg);
  --dropdownBorderActive: var(--dropDownBg);
  --dropdownBorderHover: var(--dropDownHoverBg);
  --dropDownListBg: #000000;
  --dropDownListHover: rgba(255, 255, 255, 0.1);
  --dropDownListBorder: #4d4d4d;
  --dropDownCheckSelected: #c6c6c6;
  --dropDownCheckHover: #4d4d4d;
  --dropDownCheckHoverSelected: #17e3ae;
  --dropDownNoMatchColor: #8f9397;
  --dropDownIconHighlightColor: rgba(255, 255, 255, 0.13);

  --mainMenuBg: #0a0a0a;
  --mainMenuTitleColor: #8f9397;
  --mainMenuLvl3SelectColor: #17e3ae;
  --mainMenuLvl3HoverColor: #4d4d4d;
  --mainMenuHoverColor: #4d4d4d;
  --mainMenuSelectedBgColor: rgba(255, 255, 255, 0.04);
  --mainMenuLvl2BgColor: rgba(255, 255, 255, 0.02);
  --mainMenuLvl2OpenedOrChildSelectedColor: var(--mainMenuSelectedBgColor);

  --labeledInputTextColor: #ffffff;
  --labeledInputBorderColor: #8f9397;
  --labeledInputErrorColor: #fa1478;
  --labeledInputSuccessColor: #17e3ae;
  --labeledInputHighlightColor: #ffffff;

  --graphAxis: #3d3d3d;
  --graphAxisText: #8f9397;
  --graphBarVerticalLineColor: var(--graphAxisText);
  --graphLineToolTipTextColor: #ffffff;
  --graphLineToolTipBgColor: #8f9397;
  --graphBarToolTipBgColor: #ffffff;
  --graphStackAxisText: #dee0e2;
  --legendTextColor: #dee0e2;
  --graphNetworkStatusOkLine: #17e3ae;
  --graphNetworkStatusOkRect: #1d1d1d;
  --graphNetworkStatusNullRect: var(--graphNetworkStatusOkRect);
  --graphNetworkStatusError: #fa1478;
  --graphNetworkStatusWarningStart: #f8e4a2;
  --graphNetworkStatusWarningEnd: #ffc500;
  --graphNetworkStatusToolTipColor: #ffffff;
  --graphNetworkStatusToolTipBgColor: #111111;
  --graphNetworkStatusToolTipShadowColor: rgba(255, 255, 255, 0.25);
  --graphNetworkStatusAxisText: var(--graphAxisText);

  --lteProgressBg: rgba(255, 255, 255, 0.04);
  --lteProgressFg: #17e3ae;
  --lteTitle: #c6c6c6;
  --lteTitleDescription: #4d4d4d;
  --lteDetail: #8f9397;

  --b2bButtonFontColor: #ffffff;
  --b2bButtonActionBg: rgba(255, 255, 255, 0.13);
  --b2bButtonActionBorder: transparent;
  --b2bButtonIconHover: rgba(255, 255, 255, 0.04);
  --b2bButtonIconSelected: rgba(255, 255, 255, 0.13);
  --b2bButtonIconHoverBorder: #0a0a0a;
  --b2bButtonActionHover: #4d4d4d;
  --b2bChatButtonSelectedFont: #212121;
  --b2bChatButtonSelected: #dee0e2;
  --b2bButtonActionHoverBorder: var(--b2bButtonActionHover);
  --b2bButtonPrimaryBorder: #999eff;
  --b2bButtonPrimaryBorderHover: #ccceff;
  --b2bButtonPrimaryBorderSelected: #575bcf;
  --b2bButtonSecondaryBorder: #8f9397;
  --b2bButtonSecondaryBorderHover: #dee0e2;
  --b2bButtonTertiaryFontColor: #a9fbc6;
  --b2bButtonTertiaryLightFontColor: #ffffff;
  --b2bButtonTertiaryFontColorHover: #8f9397;
  --b2bButtonQuaternaryFontColor: #a9fbc6;
  --b2bButtonQuaternaryFontColorHover: #8f9397;
  --b2bButtonSuperPrimaryColor: var(--b2bButtonFontColor);
  --b2bButtonSuperPrimaryBg: #656cff;
  --b2bButtonSuperPrimarySelectedBg: #575bcf;
  --b2bButtonSuperPrimaryHoverBorder: #ffffff;
  --b2bButtonSuperPrimaryHoverBg: #656cff;

  --tooltipContractColor: #ffffff;
  --tooltipContractBg: #111111;
  --graphGood: #135040;
  --graphWarning: #ffc500;
  --graphError: #fa1478;

  --range-slider-bg: #5c5c5c;
  --range-slider-dot: #c6c6c6;
  --slider-background: #4f4f4f;
  --slider-value: #ffffff;
  --still50: #f7f8fa;
}
