.tooltip {
  position: absolute;
  background: var(--tooltip-background);
  box-shadow: 0 0 4px var(--shadow);
  color: var(--tooltip-text);
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: normal;
  z-index: 6000;
}

.noPointerEvents {
  pointer-events: none;
}
