@import 'colors';

a {
  text-decoration: none;
  color: var(--link-text);
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.5;
  }
}
