@import 'colors';

.progressbar {
  margin: 0;
  display: flex;
  flex: 1;
  position: relative;
  height: 0.2rem;
  background-color: $pale-gray-dark;
  border-radius: 0px;
  background-clip: padding-box;
  overflow: hidden;
  &--top {
    margin: -1.6rem;
    margin-bottom: 1.4rem;
  }
  &--bottom {
    margin: 1rem -1.6rem 0 -1.6rem;
  }

  .determinate {
    position: absolute;
    background-color: inherit;
    top: 0;
    bottom: 0;
    background-color: $cornflower;
    transition: width 0.3s linear;
  }

  .indeterminate {
    background-color: $cornflower;

    &:before,
    &:after {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      animation: indeterminate 3.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    }

    &:after {
      animation: indeterminate-short 3.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
      animation-delay: 2.15s;
    }
  }

  &.hidden {
    display: none;
  }
}

@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
